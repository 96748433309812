"use strict";

/**
 * This file should contain only channel specific logic.
 * These helpers should be able to avoid some duplication when handling channels
 * More functions will be migrate from global.js to here as time goes.
 */

window.isGoogleMyBusiness = function (channel) {
  return channel.toLowerCase() === 'googlemybusiness';
};